import * as React from 'react';
import { useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useBackends from '../useBackends';
import { useDSB } from '../useDSB';
import useFeedback from '../useFeedback';
import useSdk from '../useSdk';
import { emptyAddonTemplate, emptyMembershipTemplate } from './useOMTDetailUtils';
import { ReadyStateProvider } from './useReadyState';

export const OMTContext = React.createContext({} as any);

export function WithOMT(Component) {
  return function OMTComponent(props) {
    const { api } = useSdk();
    const { get, post } = useDSB();
    const { withFeedback, pending: statusChangePending } = useFeedback();

    let { templateID, voucherID, addonID } = useParams();
    const [search, setSearch] = useSearchParams();

    let isAbo = templateID;
    let isVoucher = !templateID && voucherID;
    let isAddon = addonID;

    const location = useLocation();

    const isSubroute = (routePart) => {
      return location.pathname.includes(routePart);
    };

    if (!(isAbo && isVoucher && isAddon)) {
      isAbo = isSubroute('onlineMembershipTemplates');
      isVoucher = isSubroute('voucher');
      isAddon = isSubroute('addon-templates');
    }

    const [selectedVersionID, setSelectedVersionID] = React.useState(null);

    // which model to use
    const model = useMemo(() => {
      return isVoucher ? 'discount_campaign' : isAddon ? 'addon_template' : 'membership_template';
    }, [isVoucher, isAddon]);

    // which router param to use
    const entryId = useMemo(() => {
      return isVoucher ? voucherID : isAddon ? addonID : templateID;
    }, [isVoucher, templateID, voucherID, addonID, isAddon]);

    const {
      data: existingTemplate,
      mutate,
      isLoading,
    } = useSWR(
      entryId && api ? `omt/${model}/${entryId}` : null,
      async () => api.entry(model, entryId, { _levels: 3 }),
      {
        revalidateOnFocus: false,
      },
    );

    const { backends } = useBackends();
    // default legal templates
    const { data: legals } = useSWR(
      api ? `legals/${search.get('backend')}` : null,
      async () => {
        let backend = search.get('backend');
        const allBackends = Object.keys(backends);
        if (!backend && allBackends.length === 1) {
          backend = allBackends[0];
        }
        const legals = await api.entryList('eva_template', {
          type: 'markdown',
          name: { any: [`${backend}$markdown-AGB`, `${backend}$markdown-SEPA`, `${backend}$markdown-PRIVACY`] },
        });

        return legals.getAllItems();
      },
      {
        revalidateOnFocus: false,
      },
    );

    const membershipTemplate = useMemo(() => {
      if (!isLoading) {
        if (existingTemplate) {
          return existingTemplate;
        }

        return isAddon ? emptyAddonTemplate(legals) : emptyMembershipTemplate(legals);
      }
    }, [existingTemplate, isLoading, legals]);

    const {
      data: selectedVersion,
      mutate: mutateSelectedVersion,
      isLoading: isLoadingVersion,
    } = useSWR(
      membershipTemplate && api ? `omt/${model}/${entryId}/${selectedVersionID}` : null,
      async () => {
        if (selectedVersionID === null) {
          return (isAddon ? emptyAddonTemplate(legals) : emptyMembershipTemplate(legals)).active;
        }
        return await api.entry('membership_template_version', selectedVersionID, { _levels: 2 });
      },
      {
        revalidateOnFocus: false,
      },
    );

    React.useEffect(() => {
      if (membershipTemplate?.id) {
        setSelectedVersionID((old) => {
          // if (old !== null || old !== undefined) return;
          if (membershipTemplate.draft) {
            return membershipTemplate.draft.id;
          }

          if (membershipTemplate.active) {
            return membershipTemplate.active.id;
          }

          if (membershipTemplate.versions.length > 0) {
            return membershipTemplate.versions[0]?.id;
          }

          return old;
        });
      }
    }, [membershipTemplate, legals]);

    const {
      data: versionState,
      mutate: mutateVersionState,
      isLoading: isLoadingVersionState,
    } = useSWR(
      selectedVersion ? `omt/versionState/${selectedVersion?.draftStatus}/${selectedVersion?.id}/2` : null,
      async () => {
        if (!selectedVersion.id) {
          // create new
          return {
            state: {
              transitions: [],
            },
            user: {
              isDSBUser: false,
              can: {
                delete: true,
                publish: false,
                changeStatus: true,

                seePrices: true,
                seeTemplate: false,
                seeHector: false,
                seeComments: false,

                preview: false,
                edit: true,
              },
            },
          };
        }

        return get(`admin/membership-templates/${selectedVersion?.id}/state`);
      },
    );

    const isDSBUser = useMemo(() => versionState?.user.isDSBUser, [versionState]);
    const allowedTransitions = versionState?.state.transitions;
    const userCan = versionState?.user.can ?? {
      delete: false,
      publish: false,
      changeStatus: false,
      seePrices: false,
      seeTemplate: false,
      seeHector: false,
      seeComments: false,
      preview: false,
      edit: false,
    };

    const changeStatus = React.useCallback(
      (status: typeof selectedVersion.draftStatus) => {
        withFeedback(
          async () => {
            if (allowedTransitions.includes(status)) {
              const {
                state: { currentDraftStatus },
              } = await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
                transition: status,
              });

              // setSelectedVersion((old) => ({ ...old, draftStatus: currentDraftStatus }));
              // membershipTemplate.draft.draftStatus = currentDraftStatus;
              await mutate();
              await mutateSelectedVersion();
              // await mutateVersionState();
            }
          },
          {
            success: `Status wurde erfolgreich auf ${status} gesetzt`,
            error: 'Status konnte nicht gesetzt werden',
          },
        );
      },
      [allowedTransitions, selectedVersion, membershipTemplate],
    );

    const completeState = useMemo(
      () => ({
        prices: true,
        get hector() {
          switch (membershipTemplate?.type) {
            case 'addon':
              return true;
            default:
              return selectedVersion?.mainAbo?.length > 0;
          }
        },
        get templates() {
          if (membershipTemplate?._modelTitle === 'addon_template') {
            return selectedVersion?.contractTemplates?.length > 0;
          }

          switch (membershipTemplate?.type) {
            case 'voucher':
              return (
                selectedVersion?.contractTemplates?.length > 0 &&
                selectedVersion?.legalTemplates?.length > 0 &&
                selectedVersion?.voucherTemplates?.length > 0
              );
            default:
              return selectedVersion?.contractTemplates?.length > 0 && selectedVersion?.legalTemplates?.length > 0;
          }
        },
        selling: true,
      }),
      [selectedVersion, membershipTemplate],
    );

    return (
      <OMTContext.Provider
        value={{
          membershipTemplate,
          selectedVersion,
          selectedVersionID,
          setSelectedVersion: setSelectedVersionID,
          completeState,
          userCan,
          mutate: async () => {
            await mutate();
            await mutateSelectedVersion();
            // await mutateVersionState();
          },
          mutateVersion: async () => {
            await mutate();
            await mutateSelectedVersion();
          },
          mutateVersionState,
          allowedTransitions: allowedTransitions ?? [],
          isDSBUser,
          changeStatus,
          statusChangePending,
          templateID: entryId,
          isVoucher,
          isAbo,
          isAddon,
          isLoading,
          isLoadingVersion,
          isLoadingVersionState,
        }}
      >
        <ReadyStateProvider key={selectedVersionID}>
          <Component {...props} />
        </ReadyStateProvider>
      </OMTContext.Provider>
    );
  };
}
