import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { defaultReadyState } from '@/hooks/onlineMembershipTemplates/useReadyState';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import React, { useContext } from 'react';
import Button from '../Button';

export const CreateDraftButton = () => {
  const { membershipTemplate, selectedVersion, mutate } = useContext(OMTContext);
  const { api } = useSdk();
  const { withFeedback: draftFeedback, pending: draftPending } = useFeedback();
  function createNewDraft() {
    draftFeedback(
      async () => {
        const newVersion: any = await api.createEntry('membership_template_version', {
          membershipTemplate: membershipTemplate.id,
          draftStatus: 'Entwurf',
          minimumMembershipAge: selectedVersion.minimumMembershipAge,
          maximumMembershipAge: selectedVersion.maximumMembershipAge,
          hectorSettings: {
            ...selectedVersion.hectorSettings,
            readyState: defaultReadyState,
          },
          mainAbo: selectedVersion.mainAbo,
          abosParallel: selectedVersion.abosParallel,
          abosAfterTrial: selectedVersion.abosAfterTrial,
          abosAtEnd: selectedVersion.abosAtEnd,
          contractTemplates: selectedVersion.contractTemplates,
          legalTemplates: selectedVersion.legalTemplates,
          voucherTemplates: selectedVersion.voucherTemplates,
          trialPeriod: selectedVersion.trialPeriod,
          mainDuration: selectedVersion.mainDuration,
          mainInterval: selectedVersion.mainInterval,
          mainIntervalPrice: selectedVersion.mainIntervalPrice,
          discountDuration: selectedVersion.discountDuration,
          discountIntervalPrice: selectedVersion.discountIntervalPrice,
          discountInterval: selectedVersion.discountInterval,
          discountsMultiple: selectedVersion.discountsMultiple ?? false,
          discounts: selectedVersion.discounts,
          mainText: selectedVersion.mainText,
          fees: selectedVersion.fees,
          benefits: selectedVersion.benefits,
          addons: selectedVersion.addons,
          comments: [],
        });

        const createdNote = (await api.createEntry('comment', {
          text: '{system} Neuer Entwurf wurde aus bestehender Version erstellt',
          metadata: {
            fromVersion: selectedVersion.id,
          },
        })) as Comment & EntryResource;

        newVersion.comments.push(createdNote);
        await newVersion.save();

        membershipTemplate.versions.push(newVersion as any);
        membershipTemplate.draft = newVersion as any;
        await mutate(await membershipTemplate.save());
      },
      { success: 'Neuer Entwurf erstellt', error: 'Entwurf konnte nicht erstellt werden' },
    );
  }
  return (
    <Button $primary loading={draftPending} onClick={createNewDraft}>
      Bearbeiten
    </Button>
  );
};
